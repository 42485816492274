
.videoListComponent{
    margin-left: 5vw;
}

.videoListComponentCategory {
    font-weight: 100;
    font-size: 2em;
}

.videoScrollWrapper {
    height: 100%;
    max-width: 90vw;
}


.scrollLeft {
    position: absolute;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    opacity: 0.;
    height: 100%;
    width: 4em;
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    transform-origin: -50%;
    z-index: 10;
    transition-property: opacity;
    transition-duration: 0.3s;
    cursor: pointer;
}

.scrollLeft:hover {
    opacity: 1;
}

.caretLeft, .caretRight {
    position: relative;
    z-index: 7;
    top: 26%;
}


.scrollRight {
    position: absolute;
    height: 100%;
    width: 4em;
    border: none;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    opacity: 0.7;
    top: 0;
    bottom: 0;
    right: 0;
    transform-origin: -50%;
    z-index: 10;
    transition-property: opacity;
    transition-duration: 0.3s;
    cursor: pointer;
}

.scrollRight:hover {
    opacity: 1;
}


.videoTrackWrapper {
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    max-width: 90vw;
    z-index: 1;

}

.videoTrack::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}



.videoTrack{
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    z-index: 1;
    align-items: top;
    overflow-x: scroll;
}

.eachVideo {
    flex: 1;
    border: none;
    border-color: red;
    border-width: 1;
    margin-left: 0;
    margin-right: 0.5em;
    min-width: 250px;
    max-width: 250px;
    transition-property: min-width, max-width;
    transition-duration: 0.3s, 0.3s;
}

.eachVideo:hover {
    border-top: solid;
    border-color: red;
    border-width: 1px;
    min-width: 280px;
    max-width: 280px;
}


.invis {
    opacity: 0;
}

.invis:hover {
    opacity: 0;
}


