.commercialPageWrapper {
    position: relative;
    display: grid;
    grid-template-rows: repeat(4);
}

.fashionFilmWrapper, .videoListWrapper, .sizzleReelsWrapper {
    position: relative;
    margin-bottom: 2em;
    min-height: 250px;
}

.sizzleReelsWrapper {
    grid-row: 1;
    position: relative;
    margin-top: 2em;
}

.sizzleReels {
    position: absolute;
} 

.fashionFilmWrapper {
    position: relative;
    grid-row: 2;
}

.fashionFilm {
    position: absolute;
}

.brandedContentWrapper {
    position: relative;
    grid-row: 3;
}

.brandedContent {
    position: absolute;
}




