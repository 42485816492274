.bg {
  background: url('./imgs/bg1.jpg');
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.nameTitle {
  grid-column: 6 / 9;
  grid-row: 1 / 2;
  padding-left: 1em;
  padding-top: 2em;
}

.name {
  font-size: 4em;
}

.subTitle {
  font-size: 1em;
  color: red;
  font-weight: 100;
  font-family: 'Courier New', Courier, monospace;
}

.heroElement {
  display: block;
  grid-column: 6 / 11;
}

.contact {
  position: absolute;
  right: 5%;
  bottom: 2%;
}

.contact a,
a:visited {
  text-decoration: none;
  font-size: 1em;
  color: red;
  line-height: 2em;
  letter-spacing: 0.1em;
}

.contact a:hover {
  text-decoration: none;
  color: white;
}
