
.navBox {
    padding-left: 3em;
    padding-right: 3em;
}

.navLinks {
    display: inline-flex;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    text-decoration: none;
    font-size: 0.7em;
    color: white;
    line-height: 2em;
    letter-spacing: 0.1em;
}

.navLinks:hover {
    text-decoration: none;
    color: red;
}

.navLinkCurrent {
    text-decoration-line: overline;
    text-decoration-color: red;
    cursor: default;
}

.navLinkCurrent:hover {
    text-decoration-line: overline;
    text-decoration-color: red;
    cursor: default;
    color: white;
}