.header {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: transparent;
}

.logoName {
    vertical-align: center;
    height: 100%;
    flex-basis: content;
}

.logoTagline {
    font-size: 0.8em;
    color: silver;
}

.logo, .logo:hover, .logo:active, .logo:visited {
    padding-left: 4em;
    padding-right: 3em;
    line-height: 4.2em;
    color: red;
    font-size: 0.9em;
    font-family: 'Courier New', Courier, monospace;
    text-decoration: none;
}

.navContainer {
    flex-basis: content;
}
