.filmPageWrapper {
    position: relative;
    display: grid;
    grid-template-rows: repeat(3);
}

.shortFilmWrapper {
    grid-row: 2;
    position: relative;
    margin-top: 2em;
    margin-bottom: 3em;
    min-height: 250px;
}

.shortFilm {
    position: absolute;
}


.featureFilmWrapper {
    grid-row: 3;
    position: relative;
    margin-bottom: 3em;
    min-height: 250px;
}

.featureFilm {
    position: absolute;
}


