.videoElement {
  position: relative;
}

.heroWrapper {
  display: block;
  position: relative; 
  width: 100%; 
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */ 
}

.heroDiv {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}

.playIconDiv {
  position: absolute;
  width: 20%;
  top: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0.7;
  pointer-events: none;
}

.playIconDiv:hover {
  opacity: 1;
}

.playIcon {
  fill: white;
}

.videoCover {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.videoInfo {
  font-size: 0.8em;
  font-weight: 100;
  letter-spacing: 0.06em;
  display: block;
}

.runtime {
  font-size: 0.7em;
  color: red;
}

.roles {
  font-size: 0.8em;
  color: silver;
}

.notes {
  font-size: 0.8em;
  color: gray;
  font-style: oblique;
}